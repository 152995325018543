<template>
    <v-container fluid>
        <v-row>
            <v-col
                v-if="canFilterProfileAndUser"
                cols="12"
                lg="4"
                md="12"
                sm="12"
            >
                <label>Profile</label>
                <v-combobox
                    v-model="scheduleFilter.profileSelected"
                    :items="listProfile"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    @change="updateSchedule(); scheduleFilter.userResponsibleSelected = null"
                    dense
                ></v-combobox>
            </v-col>
            <v-col 
                v-if="canFilterProfileAndUser"
                cols="12"
                lg="4"
                md="12"
                sm="12"
            >
                <label>User</label>
                <v-combobox
                    v-model="scheduleFilter.userResponsibleSelected"
                    :items="listUser"
                    item-text="name"
                    item-value="id"
                    clearable
                    single-line
                    outlined
                    @change="updateSchedule"
                    dense
                ></v-combobox>
            </v-col>
            <v-col 
                v-if="isConstructionUser"
                cols="12"
                lg="4"
                md="12"
                sm="12"
            >
                <label>Supervisor</label>
                <v-combobox
                    :items="listUserSupervisor"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                    single-line
                    outlined
                    dense
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <!-- <v-col
                v-if="isConstructionUser"
                cols="12"
                lg="4"
                md="12"
                sm="12"
            >
                <label>Job</label>
                <v-combobox
                    clearable
                    single-line
                    outlined
                    dense
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="4"
                md="12"
                sm="12"
            >
                <label>Project</label>
                <v-combobox
                    clearable
                    single-line
                    outlined
                    dense
                ></v-combobox>
            </v-col> -->
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="text-align: right; margin-bottom: 15px;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            @click="showScheduleMeeting(0)"
                            style="margin-right: 10px;"
                        >
                            New Appointment
                        </v-btn>
                    </template>
                    <span>New Appointment</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            @click="showScheduleBlocked(0)"
                        >
                            Block Schedule
                        </v-btn>
                    </template>
                    <span>Block Schedule</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <v-divider
            v-if="userLoggedGetters.profile.viewAddAllScheduleService == 1"
        ></v-divider>

        <v-row>
            <v-col 
                cols="12"
            >
                <v-sheet height="80">
                    <v-toolbar
                        flat
                    >
                        <v-btn
                            class="mx-2"
                            @click="setToday"
                        >
                            Today
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>

                        <v-toolbar-title v-if="$refs.calendar" style="padding-left: 10px;">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                    
                        <v-menu
                            bottom
                            right
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mx-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <span>{{ typeToLabel[type] }}</span>

                                    <v-icon right>
                                        mdi-menu-down
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Day</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Week</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Month</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                 
                <v-sheet height="600">
                    <v-calendar
                        ref="calendar"
                        v-model="focus"
                        :events="scheduleListFiltered"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateSchedule"
                    >                    
                        <template v-slot:event="{ event, timeSummary }">
                            <div :style="event.done == 1 ? 'text-decoration: line-through;': '' + event.type == 'SM' && event.color == 'var(--color__meeting_not_my)' ? 'height: 20px !important; color: black; border: solid 1px var(--color__meeting) !important;' : ''  "> 
                                <strong style="margin-left: 5px;">{{ timeSummary().substring(0, timeSummary().indexOf('-') ) }} </strong> 
                                
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ event.name.length > 30 ? event.name.substring(0, 30) + '...' : event.name }}</span>
                                    </template>
                                    <span><div v-html="getToolTipDetails(event)" /></span>
                                </v-tooltip>

                                <div v-if="showCustomerDetails(event)" style="font-size: 11px; display: grid; line-height: 15px; margin-left: 5px;">
                                    <span>{{ event.customerName }}</span>
                                    <span>{{ event.customerAddress }} - {{ event.customerCity }}</span>
                                </div>

                                <div 
                                    v-if="event.type == 'SM' && event.color == 'var(--color__meeting)'"
                                    style="float: right; padding-right: 5px;"
                                >
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-icon 
                                                v-on="on"
                                                x-small 
                                                v-if="event.statusMeeting == 0" color="white"
                                            >
                                                mdi-alert-outline
                                            </v-icon>
                                        </template>
                                        <span>Pending Acceptance</span>
                                    </v-tooltip>

                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-icon 
                                                v-on="on"
                                                x-small 
                                                v-if="event.statusMeeting == 1" color="white"
                                            >
                                                mdi-check
                                            </v-icon>
                                        </template>
                                        <span>Accept</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-icon 
                                                v-on="on"
                                                x-small 
                                                v-if="event.statusMeeting == 2" color="white"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </template>
                                        <span>Declined</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </template>

                    </v-calendar>

                    <v-menu
                        v-if="showCardDetails"
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        offset-x
                    >
                        <v-card
                            color="grey lighten-4"
                            min-width="350px"
                            max-width="500px"
                            flat
                        >
                            <v-toolbar
                                :color="selectedEvent.color"
                            >
                                <v-toolbar-title 
                                    :style="selectedEvent.done == 1 ? 'color: white !important; text-decoration: line-through; width: 100%;' : selectedEvent.type == 'SM' && selectedEvent.color == 'var(--color__meeting_not_my)' ? 'color: black !important; width: 100%;' : 'color: white !important; width: 100%;'"
                                >
                                    <v-row>
                                        <v-col
                                            cols="9"
                                        >
                                            <span> {{ selectedEvent.name }} </span>
                                        </v-col>
                                        <v-col
                                            v-if="selectedEvent != null && selectedEvent != undefined && selectedEvent != ''"
                                            cols="3"
                                            @click="goToLink(selectedEvent.linkRouterName, selectedEvent.linkID)"
                                        >
                                            <span class="labelLink"> {{ selectedEvent.linkIDFormatted }} </span>
                                        </v-col>
                                    </v-row>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            v-if="selectedEvent.type == 'SM' && selectedEvent.idUserResponsible == userLoggedGetters.id"
                                            v-on="on"
                                            icon 
                                            style="border-color: var(--color__main) !important; background-color: var(--color__main) !important; color: white !important;"
                                            @click="acceptMeeting(selectedEvent)"
                                        >
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Accept</span>
                                </v-tooltip>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            v-if="selectedEvent.type == 'SM' && selectedEvent.idUserResponsible == userLoggedGetters.id"
                                            v-on="on"
                                            icon 
                                            style="border-color: var(--color__red) !important; background-color: var(--color__red) !important; color: white !important;"
                                            @click="declineMeeting(selectedEvent)"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Decline</span>
                                </v-tooltip>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            v-if="selectedEvent.type == 'SM' && selectedEvent.idUserRegister == userLoggedGetters.id"
                                            v-on="on"
                                            icon 
                                            style="border-color: var(--color__main) !important; background-color: var(--color__main) !important; color: white !important;"
                                            @click="editSchedule(selectedEvent)"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            v-if="selectedEvent.type == 'SB' || (selectedEvent.type == 'SM' && selectedEvent.idUserRegister == userLoggedGetters.id)"
                                            v-on="on"
                                            icon 
                                            style="border-color: var(--color__main) !important; background-color: var(--color__main) !important; color: white !important;"
                                            @click="deleteSchedule(selectedEvent)"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>

                            </v-toolbar>

                            <v-card-text>
                                <span
                                    style="color: black;"
                                    v-html="selectedEvent.details"
                                >
                                </span>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn
                                    class="mx-2"
                                    outlined
                                    @click="showDialogAppointment(selectedEvent)"
                                >
                                    Send to outlook
                                </v-btn>
                                <v-btn
                                    class="mx-2 dialogButtonDismiss"
                                    outlined
                                    @click="selectedOpen = false"
                                >
                                    Dismiss
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>

        <v-row>
            <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 13px;"
            >
                <strong>Legend</strong>
                <v-row>
                    <v-col
                        cols="12"
                        lg="10"
                        md="8"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkClearSelectAll"
                            label="Clear / Select All"
                            :value="true"
                            @change="clearSelectAll()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <!-- <v-col
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkUrgentToDo"
                            label="Urgent ToDo´s"
                            color="var(--color__red) !important"
                            :value="true"
                            readonly
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col> -->
                    <!-- <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkNormalToDo"
                            label="Normal ToDo´s"
                            color="var(--color__main) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col> -->
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkScheduleBlocked"
                            label="Schedule Blocked"
                            color="var(--color__silver) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkJobs"
                            label="Jobs"
                            color="var(--color__blue) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkAppointmentsFromOtherUsers"
                            label="Appointments from other users"
                            color="var(--color__meeting_not_my) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkAppointmentsYouAreAttending"
                            label="Appointments you are attending"
                            color="var(--color__meeting) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionPreDig"
                            label="Construction - Pre-Dig"
                            color="var(--color__construction_dashboard_predig) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionFrontEnd"
                            label="Construction - Front End"
                            color="var(--color__construction_dashboard_frontend) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionGradingPlumbing"
                            label="Construction - Grading / Plumbing"
                            color="var(--color__construction_dashboard_grading_plumbing) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionTileDeck"
                            label="Construction - Tile / Deck"
                            color="var(--color__construction_dashboard_tiledeck) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionEquipmentElectric"
                            label="Construction - Equipment / Electric"
                            color="var(--color__construction_dashboard_equipment_electric) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionPrepPlaster"
                            label="Construction - Prep / Plaster"
                            color="var(--color__construction_dashboard_prep_plaster) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionFence"
                            label="Construction - Fence"
                            color="var(--color__construction_dashboard_fence) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionBBQ"
                            label="Construction - BBQ"
                            color="var(--color__construction_dashboard_bbq) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkConstructionPergola"
                            label="Construction - Pergola"
                            color="var(--color__construction_dashboard_pergola) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkService"
                            label="Service"
                            color="var(--color__service) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkServiceCalls"
                            label="Service Calls"
                            color="var(--color__service) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkWorkOrderInProgress"
                            label="Work Order - In Progress"
                            color="var(--color__work_order) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkWorkOrderIncomplete"
                            label="Work Order - Incomplete"
                            color="var(--color__work_order) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkWorkOrderOnHold"
                            label="Work Order - On Hold"
                            color="var(--color__work_order) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="rowLegend">
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkInspectionScheduled"
                            label="Inspection - Scheduled"
                            color="var(--color__inspection) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                    <v-col 
                        cols="12"
                        lg="4"
                        md="4"
                        sm="12"
                    >
                        <v-checkbox
                            v-model="chkInspectionFailed"
                            label="Inspection - Failed"
                            color="var(--color__inspection) !important"
                            :value="true"
                            @change="applyFilterSchedule()"
                        ></v-checkbox>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>

        <v-speed-dial
            fixed
            v-model="openedFloatButton"
            :top="false"
            :bottom="true"
            :right="true"
            :left="false"
            :direction="'top'"
            :transition="'slide-y-reverse-transition'"
        >
            <template v-slot:activator>
                <v-btn
                    class="mx-2"
                    elevation="24"
                    outlined
                    x-large
                    fab
                    style="background-color: var(--color__red) !important; color: white !important;"
                    @click="goToService()"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-speed-dial>

        <v-dialog
            v-model="showDialogEmail"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Send Appointment
                </v-card-title>

                <v-card-text style="margin-top: 20px;">                   
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Emails</label>
                            <v-combobox
                                v-model="emailSelected"
                                persistent-hint
                                small-chips
                                multiple
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel buttonsHigher"
							outlined
							@click="showDialogEmail = false;"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 buttonsHigher"
							outlined
                            @click="sendToOutlook"
						>
							Send
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>

        <ScheduleBlock 
            v-on:methodConfirmToCall="dialogScheduleBlock.methodToCall"
            :showDialogScheduleBlock.sync="dialogScheduleBlock.show"
            :id="dialogScheduleBlock.id"
            :idAux="dialogScheduleBlock.idAux"
        />

        <ScheduleMeeting 
            v-on:methodConfirmToCall="dialogScheduleMeeting.methodToCall"
            :showDialogScheduleMeeting.sync="dialogScheduleMeeting.show"
            :id="dialogScheduleMeeting.id"
            :idAux="dialogScheduleMeeting.idAux"
        />

        <ServiceDetails 
            v-on:methodConfirmToCall="dialogServiceDetails.methodToCall"
            :showDialogSalesDetails.sync="dialogServiceDetails.show"
            :id="dialogServiceDetails.id"
            :idAux="dialogServiceDetails.idAux"
        />



    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ScheduleBlock from "@/components/Schedule/ScheduleBlock";
    import ScheduleMeeting from "@/components/Schedule/ScheduleMeeting";
    import Details from "@/components/Services/Details";
    import { PROJECT_STATUS } from "@/utilities/Enums";

    export default ({

        components: {
            ScheduleBlock: ScheduleBlock,
            ScheduleMeeting: ScheduleMeeting,
            ServiceDetails: Details
        },

        mixins: [Helpers],

        data: () => ({
            openedFloatButton: false,
            openedFloatButton2: false,
            focus: '',
            type: 'week',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,

            scheduleFilter: {
                profileSelected: null,
                userResponsibleSelected: null
            },

            scheduleList: [],
            scheduleListFiltered: [],
            listProfile: [],
            listUser: [],
            listUserSupervisor: [],

            dialogScheduleBlock: {
                show: false,
                id: 0,
                idAux: 0,
                methodToCall: ( () => {} ),
            },

            dialogScheduleMeeting: {
                show: false,
                id: 0,
                idAux: 0,
                methodToCall: ( () => {} ),
            },

            dialogServiceDetails: {
                show: false,
                id: 0,
                idAux: 0,
                methodToCall: ( () => {} ),
            },

            accessSchedulingMeeting: false,
            accessService: false,

            chkClearSelectAll: true,
            chkUrgentToDo: true,
            chkNormalToDo: true,
            chkScheduleBlocked: true,
            chkJobs: true,
            chkAppointmentsFromOtherUsers: true,
            chkAppointmentsYouAreAttending: true,
            chkConstructionPreDig: true,
            chkConstructionFrontEnd: true,
            chkConstructionGradingPlumbing: true,
            chkConstructionTileDeck: true,
            chkConstructionEquipmentElectric: true,
            chkConstructionPrepPlaster: true,
            chkConstructionFence: true,
            chkConstructionBBQ: true,
            chkConstructionPergola: true,
            chkWorkOrderInProgress: true,
            chkWorkOrderIncomplete: true,
            chkWorkOrderOnHold: true,
            chkInspectionScheduled: true,
            chkInspectionFailed: true,
            chkService: true,
            chkServiceCalls: true,

            showDialogEmail: false,
            emailSelected: [],

            PROJECT_STATUS
        }),

        props: {
            id: {
                default: 0
            },

            params: {
                default: ""
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            showCardDetails() {
                return this.selectedEvent.type != 'SR' || 
                     ( 
                       this.selectedEvent.type == 'SR' && 
                       this.selectedEvent.idUserResponsible != this.userLoggedGetters.id &&
                       this.selectedEvent.idUserRegister != this.userLoggedGetters.id
                     )
            },

            canFilterProfileAndUser() {
                return this.userLoggedGetters.profile.viewAddAllScheduleService == 1 || this.userLoggedGetters.profile.viewSchedulerOtherUsers == 1;
            },

            isConstructionUser() {
                return this.userLoggedGetters.profile.constructionUser == 1;
            }
        },

        methods: {

            clearSelectAll() {
                
                this.chkNormalToDo = this.chkClearSelectAll;
                this.chkScheduleBlocked = this.chkClearSelectAll;
                this.chkJobs = this.chkClearSelectAll;
                this.chkAppointmentsFromOtherUsers = this.chkClearSelectAll;
                this.chkAppointmentsYouAreAttending = this.chkClearSelectAll;
                this.chkConstructionPreDig = this.chkClearSelectAll;
                this.chkConstructionFrontEnd = this.chkClearSelectAll;
                this.chkConstructionGradingPlumbing = this.chkClearSelectAll;
                this.chkConstructionTileDeck = this.chkClearSelectAll;
                this.chkConstructionEquipmentElectric = this.chkClearSelectAll;
                this.chkConstructionPrepPlaster = this.chkClearSelectAll;
                this.chkConstructionFence = this.chkClearSelectAll;
                this.chkConstructionBBQ = this.chkClearSelectAll;
                this.chkConstructionPergola = this.chkClearSelectAll;
                this.chkWorkOrderInProgress = this.chkClearSelectAll;
                this.chkWorkOrderIncomplete = this.chkClearSelectAll;
                this.chkWorkOrderOnHold = this.chkClearSelectAll;
                this.chkInspectionScheduled = this.chkClearSelectAll;
                this.chkInspectionFailed = this.chkClearSelectAll;
                this.chkService = this.chkClearSelectAll;
                this.chkServiceCalls = this.chkClearSelectAll;

                this.applyFilterSchedule();
            },

            async applyFilterSchedule() {

                let scheduleListUrgentToDo = [];
                let scheduleListNormalToDo = [];
                let scheduleListScheduleBlocked = [];
                let scheduleListJobs = [];
                let scheduleListAppointmentsFromOtherUsers = [];
                let scheduleListAppointmentsYouAreAttending = [];
                let scheduleListWorkOrderInProgress = [];
                let scheduleListWorkOrderIncomplete = [];
                let scheduleListWorkOrderOnHold = [];
                let scheduleListInspectionScheduled = [];
                let scheduleListInspectionFailed = [];
                let scheduleListConstructionPreDig = [];
                let scheduleListConstructionFrontEnd = [];
                let scheduleListConstructionGradingPlumbing = [];
                let scheduleListConstructionBrickTile = [];
                let scheduleListConstructionEquipmentElectric = [];
                let scheduleListConstructionPlaster = [];
                let scheduleListConstructionBBQ = [];
                let scheduleListConstructionPergola = [];
                let scheduleListService = [];
                let scheduleListServiceCalls = [];

                if (this.chkUrgentToDo == true)   {
                    scheduleListUrgentToDo = this.scheduleList.filter(sch => sch.urgent == 1);
                }

                if (this.chkNormalToDo == true)   {
                    scheduleListNormalToDo = this.scheduleList.filter(sch => sch.type == 'TD' && sch.urgent != 1);
                }

                if (this.chkScheduleBlocked == true)   {
                    scheduleListScheduleBlocked = this.scheduleList.filter(sch => sch.type == 'SB' && sch.urgent != 1);
                }

                if (this.chkJobs == true)   {
                    scheduleListJobs = this.scheduleList.filter(sch => sch.type == 'SR' && sch.urgent != 1);
                }

                if (this.chkAppointmentsFromOtherUsers == true)   {
                    scheduleListAppointmentsFromOtherUsers = this.scheduleList.filter(sch => sch.type == 'SM' && sch.color == 'var(--color__meeting_not_my)');
                }

                if (this.chkAppointmentsFromOtherUsers == true)   {
                    scheduleListAppointmentsYouAreAttending = this.scheduleList.filter(sch => sch.type == 'SM' && sch.color != 'var(--color__meeting_not_my)');
                }

                if (this.chkWorkOrderInProgress == true)   {
                    scheduleListWorkOrderInProgress = this.scheduleList.filter(sch => sch.type == 'WO1');
                }

                if (this.chkWorkOrderIncomplete == true)   {
                    scheduleListWorkOrderIncomplete = this.scheduleList.filter(sch => sch.type == 'WO3');
                }

                if (this.chkWorkOrderOnHold == true)   {
                    scheduleListWorkOrderOnHold = this.scheduleList.filter(sch => sch.type == 'WO5');
                }

                if (this.chkInspectionScheduled == true)   {
                    scheduleListInspectionScheduled = this.scheduleList.filter(sch => sch.type == 'IP1');
                }

                if (this.chkInspectionFailed == true)   {
                    scheduleListInspectionFailed = this.scheduleList.filter(sch => sch.type == 'IP3');
                }

                if (this.chkConstructionPreDig == true)   {
                    scheduleListConstructionPreDig = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_PREDIG_REBAR.id);
                }

                if (this.chkConstructionFrontEnd == true)   {
                    scheduleListConstructionFrontEnd = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_DIGDEMO.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_FRONTEND_BACKFILL.id);
                }

                if (this.chkConstructionGradingPlumbing == true)   {
                    scheduleListConstructionGradingPlumbing = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_PLUMBING.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION.id);
                }

                if (this.chkConstructionBrickTile == true)   {
                    scheduleListConstructionBrickTile = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_BRICKTILE_DECKINSPECTION.id);
                }

                if (this.chkConstructionEquipmentElectric == true)   {
                    scheduleListConstructionEquipmentElectric = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION.id);
                }

                if (this.chkConstructionPlaster == true)   {
                    scheduleListConstructionPlaster = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_FINALWALKTHROUGHT.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id);
                }

                if (this.chkConstructionBBQ == true)   {
                    scheduleListConstructionBBQ = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_BBQ_FINALINSPECTION.id);
                }

                if (this.chkConstructionPergola == true)   {
                    scheduleListConstructionPergola = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FOOTERFRAME.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_PERGOLA_FINALINSPECTION.id);
                }

                if (this.chkService == true)   {
                    scheduleListService = this.scheduleList.filter(sch => sch.type == 'AC' && sch.statusProject >= this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id && sch.statusProject <= this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id);
                }

                if (this.chkServiceCalls == true)   {
                    scheduleListServiceCalls = this.scheduleList.filter(sch => sch.type == 'SC');
                }

                this.scheduleListFiltered = [
                    ...scheduleListUrgentToDo, 
                    ...scheduleListNormalToDo, 
                    ...scheduleListScheduleBlocked,
                    ...scheduleListJobs,
                    ...scheduleListAppointmentsFromOtherUsers,
                    ...scheduleListAppointmentsYouAreAttending,
                    ...scheduleListWorkOrderInProgress,
                    ...scheduleListWorkOrderIncomplete,
                    ...scheduleListWorkOrderOnHold,
                    ...scheduleListInspectionScheduled,
                    ...scheduleListInspectionFailed,
                    ...scheduleListConstructionPreDig,
                    ...scheduleListConstructionFrontEnd,
                    ...scheduleListConstructionGradingPlumbing,
                    ...scheduleListConstructionBrickTile,
                    ...scheduleListConstructionEquipmentElectric,
                    ...scheduleListConstructionPlaster,
                    ...scheduleListConstructionBBQ,
                    ...scheduleListConstructionPergola,
                    ...scheduleListService,
                    ...scheduleListServiceCalls
                ];
            },

            viewDay ({ date }) {
                this.focus = date
                this.type = 'day'
            },

            getEventColor (event) {
                return event.color
            },

            setToday () {
                this.focus = ''
            },

            prev () {
                this.$refs.calendar.prev()
            },

            next () {
                this.$refs.calendar.next()
            },

            showEvent ({ nativeEvent, event }) {

                const open = () => {
                    this.selectedEvent = event;
                    this.selectedElement = nativeEvent.target;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));

                    if (event.idUserResponsible == this.userLoggedGetters.id || 
                        event.idUserRegister == this.userLoggedGetters.id) {
                        this.dialogServiceDetails = {
                            id: event.id,
                            idAux: Math.random(),
                            show: event.type == 'SR',
                            methodToCall: this.updateSchedule,
                        }
                    }
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    requestAnimationFrame(() => requestAnimationFrame(() => open()))
                } 
                else {
                    open()
                }

                nativeEvent.stopPropagation()
            },

            async updateSchedule () {

                this.showLoading();

                let scheduleFilterRequest = {
                    idProfile: 0,
                    idUser: 0
                }

                let listUserResponse = await this.$store.dispatch("userModule/List");
                this.listUserSupervisor = listUserResponse.filter(usr => usr.constructionSupervisor == 1);

                if (this.scheduleFilter.profileSelected != null && this.scheduleFilter.profileSelected != undefined) {
                    scheduleFilterRequest.idProfile = this.scheduleFilter.profileSelected.id
                    this.listUser = this.listUser.filter(usr => usr.idProfile == scheduleFilterRequest.idProfile)
                }

                if (this.scheduleFilter.userResponsibleSelected != null && this.scheduleFilter.userResponsibleSelected != undefined) {
                    scheduleFilterRequest.idUser = this.scheduleFilter.userResponsibleSelected.id
                }
                
                await this.loadProfilesAndUsers();
                await this.addFirstItemList();

                this.scheduleList = await this.$store.dispatch("scheduleModule/ListAllSchedule", scheduleFilterRequest);
                this.applyFilterSchedule();

                this.hideLoading();
            },

            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },

            showScheduleBlocked() {
                this.dialogScheduleBlock = {
                    show: true,
                    idAux: Math.random(),
                    methodToCall: this.updateSchedule,
                }
            },

            async deleteSchedule(selectedEvent) {
                this.showLoading();
                
                if (selectedEvent.type == 'SB') {
                    await this.$store.dispatch("scheduleModule/DateBlockedDelete", selectedEvent.id);
                }
                else if (selectedEvent.type == 'SM') {
                    await this.$store.dispatch("scheduleModule/DateMeetingDelete", selectedEvent.id);
                }
                
                this.updateSchedule();
                this.selectedOpen = false;
                this.hideLoading();
            },

            async editSchedule(selectedEvent) {
            
                this.dialogScheduleMeeting = {
                    id: selectedEvent.id,
                    idAux: Math.random(),
                    show: true,
                    methodToCall: this.updateSchedule,
                }
            },

            showScheduleMeeting(id) {
            
                this.dialogScheduleMeeting = {
                    id: id,
                    idAux: Math.random(),
                    show: true,
                    methodToCall: this.updateSchedule,
                }
            },

            async acceptMeeting(selectedEvent) {
                this.showLoading();

                await this.$store.dispatch("scheduleModule/AcceptMeeting", { idMeeting: selectedEvent.id, idUser: selectedEvent.idUserResponsible, statusId: 1  });

                this.updateSchedule();
                this.selectedOpen = false;
                this.hideLoading();
            },

            async declineMeeting(selectedEvent) {
                this.showLoading();
            
                await this.$store.dispatch("scheduleModule/AcceptMeeting", { idMeeting: selectedEvent.id, idUser: selectedEvent.idUserResponsible, statusId: 2  });

                this.updateSchedule();
                this.selectedOpen = false;
                this.hideLoading();
            },

            goToService() {
                this.$router.push({ path: "/service/serviceForm" });
            },

            async getLists() {
                await this.loadProfilesAndUsers();
                await this.addFirstItemList();
            },  

            async loadProfilesAndUsers() {
                
                this.listProfile = await this.$store.dispatch("profileModule/List");
                let listUser = await this.$store.dispatch("userModule/List");
                let idProfileSelected = 0;

                if (this.scheduleFilter.profileSelected != null && 
                    this.scheduleFilter.profileSelected != undefined && 
                    this.scheduleFilter.profileSelected.id != 0) {
                    idProfileSelected = this.scheduleFilter.profileSelected.id;

                    listUser = listUser.filter(usr => usr.idProfile == idProfileSelected);
                }

                this.listUser = [...listUser];

                if (this.userLoggedGetters.listIDUsers != null && 
                    this.userLoggedGetters.listIDUsers != undefined &&
                    this.userLoggedGetters.listIDUsers.toString().trim() != '') {
                    const listIDUsers = this.userLoggedGetters.listIDUsers.toString().split(';');

                    let newListUsers = [];

                    listIDUsers.forEach(idUser => {

                        if (idUser != null && idUser != undefined && idUser.toString().trim() != '') {
                            const userFiltered = listUser.filter(item => item.id.toString() == idUser)[0];

                            if (userFiltered && userFiltered != null && userFiltered != undefined) {
                                newListUsers.push(userFiltered);
                                this.listUser = newListUsers;
                            }
                        }
                    });
                }
            },

            async addFirstItemList() {

                const itemAllProfiles = {
                    id: 0,
                    name: 'All Profiles'
                }

                const itemAllUsers = {
                    id: 0,
                    name: `All Users`
                }

                this.listProfile.unshift(itemAllProfiles)

                this.listUser.unshift(itemAllUsers)

                if (this.scheduleFilter.profileSelected == null || this.scheduleFilter.profileSelected == undefined) {
                    this.scheduleFilter.profileSelected = itemAllProfiles;
                }

                if (this.scheduleFilter.userResponsibleSelected == null || this.scheduleFilter.userResponsibleSelected == undefined) {
                    this.scheduleFilter.userResponsibleSelected = itemAllUsers;
                }
            },

            async checkAccess() {
                this.accessSchedulingMeeting = false;
                this.accessService = false;

                const listRoutesFromLocalStorage = localStorage.getItem('listRouters');

                if (listRoutesFromLocalStorage !== null && listRoutesFromLocalStorage !== undefined) {
                    const listRoutesJSON = JSON.parse(listRoutesFromLocalStorage)

                    let accessRoute = listRoutesJSON.filter(rte => rte.name == 'schedulingMeeting');
                    this.accessSchedulingMeeting = accessRoute != null && accessRoute != undefined && accessRoute.length > 0;

                    accessRoute = listRoutesJSON.filter(rte => rte.name == 'serviceForm');
                    this.accessService = accessRoute != null && accessRoute != undefined && accessRoute.length > 0;
                }

            },

            async goToLink(routerName, id) {                
                this.$router.push({ name: routerName, params: { id } });
            },

            async showDialogAppointment(selectedEvent) {
                this.selectedEvent = selectedEvent;
                this.showDialogEmail = true;
                this.emailSelected = [];
                this.emailSelected.push(this.userLoggedGetters.email);
            },

            async sendToOutlook() {

                if (this.selectedEvent.type == 'SR') {

                    this.showLoading();

                    const sendAppointmentRequest = {
                        idService: this.selectedEvent.id,
                        emails: this.emailSelected.join(";")
                    };

                    const result = await this.$store.dispatch("serviceModule/SendScheduleOutlook", sendAppointmentRequest);
                    
                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.hideLoading();
                    this.showDialogEmail = false;
                }
            },

            async checkParams() {

                if (this.params != null && this.params != undefined && this.params != "" ) {
                    const jsonParams = JSON.parse(this.params);

                    if (jsonParams != null && jsonParams != undefined) {
                        if (jsonParams.showAppointment == true) {
                            this.dialogServiceDetails = {
                                id: this.id,
                                idAux: Math.random(),
                                show: true,
                                methodToCall: this.updateSchedule,
                            }
                        }
                    }
                }
            },

            getToolTipDetails(event) {
                const details = event.details.toString().replaceAll("<a href=", "<a style='color: white !important;' href=");
                return details;
            },

            showCustomerDetails(event) {
                return (this.type == 'week' || this.type == 'day') &&
                       event.customerName != null && event.customerName != undefined && event.customerName != '' &&
                       event.customerAddress != null && event.customerAddress != undefined && event.customerAddress != ''
            }
        },

        async mounted () {
            this.showLoading();
            this.$refs.calendar.checkChange();
            await this.getLists();
            await this.checkAccess();
            await this.checkParams();
            this.hideLoading();
        },
    })
</script>

<style scoped>
    .rowLegend {
        height: 30px !important;
    }    

    @media only screen and (max-width: 1000px) {
        .rowLegend {
            height: inherit !important;
        }
    }
</style>

