<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogScheduleBlock"
			transition="dialog-top-transition"
			persistent
			width="700"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-form
				ref="form"
				v-model="validForm"
				lazy-validation
			>
				<v-card>
                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
						Block Schedule 
					</v-card-title>

					<v-card-text style="margin-top: 20px;">

						<v-row>
							<v-col cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<v-menu
									ref="mnuDateBlocked"
									v-model="mnuDateBlocked"
                                    :rules=[validations.required]
									transition="scale-transition"
                                    :close-on-content-click="false"
									offset-y
									max-width="290px"
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<label>Date to Block</label>
										<v-text-field
											v-model="dateBlockedRangeText"
											prepend-inner-icon="mdi-calendar"
											v-bind="attrs"
                                            readonly
											outlined
											single-line
											v-on="on"
                                            dense
										></v-text-field>
									</template>
									<v-date-picker
										v-model="dateBlocked"
										no-title
                                        :min="today"
										color="primary lighten-1"
                                        range
									></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
                    
                        <v-row>
                            <v-col 
                                cols="6"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <label>Start Time</label>
                                <v-text-field
                                    v-model="timeStartDisplay"
                                    :rules=[validations.required]
                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                    v-mask="'##:## AA'"
                                    outlined
                                    single-line
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col 
                                cols="6"
                                lg="6"
                                md="6"
                                sm="6"
                            >
                                <label>End Time</label>
                                <v-text-field
                                    v-model="timeEndDisplay"
                                    :rules=[validations.required]
                                    prepend-inner-icon="mdi-clock-time-four-outline"
                                    v-mask="'##:## AA'"
                                    outlined
                                    single-line
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
						<v-row>
							<v-col cols="12"
								lg="12"
								md="12"
								sm="12"
							>
								<label>Description</label>
								<v-textarea
									v-model="scheduleBlockRequest.description"
									single-line
									rows="4"
									row-height="30"
									outlined
								>
								</v-textarea>
							</v-col>
						</v-row>

					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions 
                        class="justify-center"
                    >
							<ActionButtons
								:request="scheduleBlockRequest"
								:loading="loading"
								:showButtons="{
									cancel: true,
									save: true
								}"
								@cancel="cancel"
								@save="save"
							/>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

	export default {

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        props: {
            id: {
                default: 0
            },

            idAux: {
                default: 0
            },

			showDialogScheduleBlock: {
				default: false
			},
		},
		
        data: vm => ({

            validForm: true,
			loading: false,

			scheduleBlockRequest: {
                id: 0,
                idUser: "",
                dateBlocked: "",
                description: ""
            },
            menuTimeStart: false,
            menuTimeEnd: false,
            AMPMStart: "AM",
			timeStart: null,
			timeStartDisplay: null,
            AMPMEnd: "AM",
			timeEnd: null,
			timeEndDisplay: null,
            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateBlocked: [vm.today],
            mnuDateBlocked: false,
            
            validations: {
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            dateBlockedRangeText () {
                let newDates = [];

                if (this.dateBlocked != null && this.dateBlocked != undefined && this.dateBlocked.length > 0) {
                    this.dateBlocked.forEach(itemDate => {
                        newDates.push(this.formatDate(itemDate));
                    });
                }

                if (newDates.length > 0) {
                    return newDates.join(' ~ ');
                }
                else {
                    return this.formatDate(this.today);
                }
            },
        },

        watch: {
            "idAux"() {
                this.clearFields();
            },
        },

        methods: {

			async clearFields() {
				this.dateBlocked = [this.today];
				this.dateBlockedStart = "";
				this.dateBlockedEnd = null;
				this.AMPMStart = "AM";
				this.AMPMEnd = "AM";
				this.timeStart = null;
				this.timeStartDisplay = null;
				this.timeEnd = null;
				this.timeEndDisplay = null;
				this.scheduleBlockRequest.description = "";
			},
			
            async cancel() {

                this.$emit('update:showDialogScheduleBlock', false);
				this.clearFields();
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
					this.loading = true;
					this.showLoading();

					this.scheduleBlockRequest.id = this.id;
					this.scheduleBlockRequest.idUser = this.userLoggedGetters.id;
                    let result = null;

                    if (this.dateBlocked != null && this.dateBlocked != undefined) {

                        let firstDate = this.dateBlocked[0];
                        let lastDate = this.dateBlocked[0];

                        if (this.dateBlocked.length == 2) {
                            firstDate = this.dateBlocked[0];
                            lastDate = this.dateBlocked[1];
                        }
					
                        this.scheduleBlockRequest.dateBlockedStart = firstDate + " " + this.timeStartDisplay;
                        this.scheduleBlockRequest.dateBlockedEnd = lastDate + " " + this.timeEndDisplay;

                        result = await this.$store.dispatch("scheduleModule/DateBlockedCreateUpdate", this.scheduleBlockRequest);

                        if (result.success == true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }
                    }

					this.$emit('methodConfirmToCall');
					this.loading = false;
					this.hideLoading();
                }
            },
        }
    };
</script>